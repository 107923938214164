<template>
  <div class="contact-us-page">
    <v-row justify="center" align="center">
      <!--<img src="../assets/images/slide11.jpeg"/>
     <v-card max-width="630" class="mx-auto">
      <v-carousel
        :continuous="true"
        :interval="4000"
        :cycle="cycle"
        :show-arrows="false"
        hide-delimiter-background
        delimiter-icon="mdi-checkbox-blank-circle"
        height=""
      >-->
        <!-- <v-carousel-item 
      v-for="(item,i) in sliderData"
      :key="i"
      :src="image_base_url + item.trans[0].image">
      </v-carousel-item>  -->
       <!-- <v-carousel-item
          v-for="(item, i) in sliderData"
          :key="i"
          :src="item.image"
        >
        </v-carousel-item>
        <v-carousel-item src="../assets/images/slider-1.png"> </v-carousel-item>
        <v-carousel-item src="../assets/images/slider-1.png"> </v-carousel-item>
        <v-carousel-item src="../assets/images/slider-1.png"> </v-carousel-item> 
      </v-carousel>
      </v-card>-->
      <v-col>
        <div class="text-center">
          <vuetify-logo />
        </div>
        <h2>فروع سمو النخيل بالرياض</h2>
        <br>
        <br>
        <span>يوجد لدينا ثلاث فروع ونقاط بيع داخل الرياض</span>
        <br>
        <br>
        <span> فرع:  سمو النخيل للتمور العزيزية - العزيزية - الطريق الدائري الجنوبي </span>
        <br>
        <br>
        <span> فرع:  سمو النخيل للتمور عتيقة - عتيقة - سوق التمور  </span>
        <div class="contact-item">
         

          <!--<div class="icon green">
            <i class="fab fa-whatsapp"></i>
          </div>
          <a
            :href="
              'https://api.whatsapp.com/send?phone=' + contactData.whatsapp
            "
            target="_blank"
            >{{ contactData.whatsapp }}</a
          >
          <a href="https://api.whatsapp.com/send?phone=966552983660" target="_blank"> 966552983660 ::الإدارة</a>
        </div> -->
        
       <!-- <div class="contact-item">
          <div class="icon blue">
            <i class="far fa-envelope"></i>
          </div>
          <a :href="'mailto:' + contactData.contact_email" target="_blank">{{
            contactData.contact_email
          }}</a>-->
         <!-- <a href="smoualnakhel22@gmail.com" target="_blank">smoualnakhel22@gmail.com</a> 
        --></div>
        
      </v-col>
      
    </v-row>
  </div>

</template>

<script>
import { ServiceFactory } from "../services/ServiceFactory";
const aboutService = ServiceFactory.get("about");

import VuetifyLogo from "../components/VuetifyLogo.vue";
export default {
  data: () => ({
    model:null,
    // social: {},
    social: {
      snapchat: "",
      twitter: "",
      instagram: "",
    },
    // contactData: null,
    contactData: {
      contact_phone: "",
      contact_email: "",
      whatsapp: "",
    },
  }),
  components: {
    VuetifyLogo,
  },
  created() {
    this.fetchAllItems();
    //, this.fetchAllContact();
    // if (this.$route) {
    // }

    console.log(this.$route.name);
  },
  methods: {
    async fetchAllItems() {
      this.dataLoading = true;
      const data = await aboutService.getSocialData();
      this.contactData.contact_phone = data.contact_phone;
      this.contactData.contact_email = data.contact_email;
      this.contactData.whatsapp = data.whatsapp;
      this.social.snapchat = data.snapchat;
      this.social.twitter = data.twitter;
      this.social.instagram = data.instagram;
      this.dataLoading = false;
    },
    // async fetchAllContact() {
    //   this.dataLoading = true;
    //   const contactData = await aboutService.getContactData();
    //   this.contactData = contactData.contactus;
    //   console.log(this.contactData);
    //   this.dataLoading = false;
    // },
  },
};
</script>

<style>
.contact-us-page {
  text-align: center;
}
.contact-us-page h2 {
  margin: 50px 0 30px 0;
  text-align: center;
  color: #614c37 !important;
  font-size: 15px !important;
}
.contact-us-page .contact-item {
  display: flex;
  direction: rtl;
  align-items: center;
  margin-bottom: 30px;
}
.contact-us-page .contact-item .icon {
  color: #fff;
  width: 80px;
  height: 80px;
  font-size: 40px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  flex: 0 0 80px;
}
.contact-us-page .contact-item .green {
  background-color: #20d268 !important;
}
.contact-us-page .contact-item .baby-blue {
  background-color: #1da1f2;
}
.contact-us-page .contact-item .blue {
  background-color: #3f51b5 !important;
}
.contact-us-page .contact-item .yellow {
  background-color: #fffc00 !important;
}
.contact-us-page .contact-item .purple {
  background-color: #c13584 !important;
}
.contact-us-page .contact-item a {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  background-color: #fff;
  direction: ltr;
  width: 100%;
  text-align: right;
  padding: 9px;
  border-radius: 12px 0 0 12px;
  text-decoration: none;
  color: #000;
  font-weight: normal;
  font-size: 15px;
}
</style>
